import axios from "axios";
import jwt from "jsonwebtoken";
import store from "@/store";
import router from "@/router";
import { BASE_URL_API } from "@/config/config";

const instance = axios.create({
  baseURL: BASE_URL_API,
  timeout: 100000,
});

instance.interceptors.request.use(async function (config) {
  let token = store.getters.getAuth && store.getters.getAuth.accessToken;

  if (!token) {
    // if (router.currentRoute.meta.clinica) {
    //   router.push({ name: "login", params: { id: router.currentRoute.params.id } });
    // }

    //   try {
    //     const resp = await axios({
    //       method: "POST",
    //       url: `${BASE_URL_API}auth/api`,
    //       data: { apiCode: API_CODE }
    //     })
    //     token = resp.data.accessToken

    //   } catch (error) {
    //     console.log(error)
    //   }

    // } else {
    try {
      const decoded = await jwt.decode(token);
      if (Date.now() >= decoded.exp * 1000) {
        try {
          await store.dispatch(
            "refreshToken",
            store.getters.getAuth.refreshToken
          );
          token = store.getters.getAuth && store.getters.getAuth.accessToken;
          if (
            store.getters.getRefreshTokenError &&
            store.getters.getRefreshTokenError.status === 403
          ) {
            store.commit("SET_AUTH");
            router.push({
              name: "Login",
              params: { id: router.currentRoute.params.id },
            });
          }
        } catch (error) {
          router.push({
            name: "Login",
            params: { id: router.currentRoute.params.id },
          });
        }
      }
    } catch (error) {
      console.log("axios interceptor -> token error:", error);
    }
  }

  config.headers.Authorization = "Bearer " + token;

  return config;
});

export default instance;
