import http from "./http"

const Parametro = {
  find: async (id) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `parametros/${id}`,
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })
    })
  },


  findById: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `parametros?id=${payload.id}`,
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })
    })
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `parametros`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })
    })
  },
  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `parametros`,
        data: { ...payload }
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })
    })
  }
}

export default Parametro;