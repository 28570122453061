<template>
  <div>
    <v-card>
      <v-card-title>Dados da Empresa</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="salvar"
        >
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Nome Empresa"
                ref="nome"
                outlined
                dense
                v-model="form.nome"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Atividade"
                ref="atividade"
                outlined
                dense
                v-model="form.atividade"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-text-field
                label="CNPJ"
                ref="documento"
                outlined
                dense
                v-model="form.documento"
                :rules="[rules.required, rules.documento]"
                v-mask="['##.###.###/####-##']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="Telefone"
                ref="telefone"
                outlined
                dense
                v-model="form.telefone"
                :rules="[rules.required]"
                v-mask="['(##) #####-####']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="Logradouro"
                ref="logradouro"
                outlined
                dense
                v-model="form.endereco.logradouro"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="4" sm="2" md="2" lg="2">
              <v-text-field
                label="Número"
                ref="numero"
                outlined
                dense
                v-model="form.endereco.numero"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="2" md="2" lg="2">
              <v-text-field
                label="Complemento"
                ref="complemento"
                outlined
                dense
                v-model="form.endereco.complemento"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="Bairro"
                ref="bairro"
                outlined
                dense
                v-model="form.endereco.bairro"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="9" sm="3" md="3" lg="3">
              <v-text-field
                label="Cidade"
                ref="cidade"
                outlined
                dense
                v-model="form.endereco.cidade"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="3" sm="1" md="1" lg="1">
              <v-text-field
                label="UF"
                ref="uf"
                outlined
                dense
                v-model="form.endereco.uf"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="CEP"
                ref="cep"
                outlined
                dense
                v-model="form.endereco.cep"
                :rules="[rules.required]"
                v-mask="['#####-###']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" align="right">
              <v-btn
                dark
                depressed
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="loading"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Parâmetros para cálculos</v-card-title>
      <v-card-text>
        <v-form ref="form2" lazy-validation @submit.prevent="salvarParametros">
          <v-row>
            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="Imposto(%)"
                ref="imposto"
                outlined
                dense
                v-model="formParam.percentImposto"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="Empresa(%)"
                ref="empresa"
                outlined
                dense
                v-model="formParam.percentEmpresa"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3">
              <v-text-field
                label="Risco(%)"
                ref="risco"
                outlined
                dense
                v-model="formParam.percentRisco"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" lg="3" align="right">
              <v-btn
                dark
                depressed
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="loading"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Empresa from "@/service/empresa";
import Parametro from "@/service/parametro";
import { mapGetters } from "vuex";
export default {
  name: "Empresa",

  data() {
    return {
      valid: true,
      loading: false,
      form: {
        nome: "",
        documento: "",
        telefone: "",
        endereco: {
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          uf: "",
          cep: "",
        },
      },
      formParam: {
        percentEmpresa: "",
        percentImposto: "",
        percentRisco: "",
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
        documento: (value) => {
          if (value && value.length != 14 && value.length != 18)
            return (value.length < 14 ? "CPF" : "CNPJ") + " inválido.";

          return true;
        },
      },
    };
  },
  computed: {
    pf() {
      const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

      return pattern.test(this.form.documento);
    },
    ...mapGetters(["getAuth"]),
  },
  methods: {
    formToUpperCase() {
      if (!this.form) return;
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] && typeof this.form[f] == "string") {
          this.form[f] = this.form[f].toUpperCase();
        } else if (this.form[f] && typeof this.form[f] == "object") {
          Object.keys(this.form[f]).forEach((i) => {
            if (this.form[f][i] && typeof this.form[f][i] == "string")
              this.form[f][i] = this.form[f][i].toUpperCase();
          });
        }
      });
    },
    async salvar() {
      this.formToUpperCase();

      if (!this.$refs.form.validate()) return;

      try {
        const payload = {
          ...this.form,
          empresa: true,
        };
        await Empresa.save(payload);

        this.$emit("sucesso");
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Ocorreu um erro ao salvar os dados!",
          type: "error",
        });
        console.log(error);
      }
    },
    async salvarParametros() {
      if (!this.$refs.form2.validate()) return;

      try {
        this.formParam = await Parametro.save({ ...this.formParam });
        this.$notify({
          title: "Sucesso",
          text: "Parâmetros salvos!",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Ocorreu um erro ao salvar os parâmetros!",
          type: "error",
        });
        console.log(error);
      }
    },
    async fetchParametros() {
      this.formParam = await Parametro.find(this.getAuth.empresa.id);
    },
    async fetchEmpresa() {
      const resp = await Empresa.find(this.getAuth.empresa.id);
      const endereco = {
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
      };
      const form = {
        nome: "",
        documento: "",
        telefone: "",
        endereco: { ...endereco },
      };
      console.log(resp);

      resp.endereco = resp && !resp.endereco ? endereco : resp.endereco;
      this.form = resp ? resp : form;
    },
  },
  async created() {
    await this.fetchParametros();
    await this.fetchEmpresa();
  },
};
</script>

<style scoped>
div input {
  text-transform: uppercase;
}
</style>
