import http from "./http";

const empresa = {
  find: async (id) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `empresas/${id}`,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `empresas`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
}

export default empresa;